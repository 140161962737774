import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from '../scss/light-slider.scss';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
class ProjectDetailsModal extends Component {
  render() {
    if (this.props.data) {
      const technologies = this.props.data.technologies;
      const images = this.props.data.images;
      var title = this.props.data.title;
      var description = this.props.data.description;

      if (this.props.data.technologies) {
        var tech = technologies.map((icons, i) => {
          return (
            <li className="list-inline-item mx-3" key={i}>
              <span>
                <div className="text-center skill-tile">
                  <i className={icons.class} style={{ fontSize: '40px'}}>
                    <p className="text-center" style={{ fontSize: '20px', fontFamily:'Segoe UI' }}>
                      {icons.name}
                    </p>
                  </i>
                </div>
              </span>
            </li>
          );
        });
        if (this.props.data.url && this.props.data.url !== "" ){
          
          var url = (
          <a href={this.props.data.url} target="_blank" rel="noopener noreferrer" className="link-href">
            <i className="fas fa-external-link-alt" style={{marginLeft: '10px'}}></i>
          </a>
          )

        }

        if (this.props.data.images) {
          var img = images.map((elem, i) => {
            return <div key={i} data-src={elem} />;
          });
        }
      }
    }
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-inside"
        backgroundColor = "white"
      >
        <span onClick={this.props.onHide} className="modal-close">
          <i className="fas fa-times fa-3x close-icon"></i>
        </span>
        <div className="col-md-12">
          <div className="col-md-10 mx-auto" style={{ paddingBottom: '50px' }}>
            <AwesomeSlider
              cssModule={AwesomeSliderStyles}
              animation="basicUsageAnimation"
              className="slider-image"
            >
              {img}
            </AwesomeSlider>
          </div>
          <div className="col-md-10 mx-auto">
            <h2 className="project-title-settings">{title}
            {url}
            </h2>
            <p className="modal-description">{description}</p>
            <div className="col-md-12 text-center">
              <ul className="list-inline mx-auto skill-icon">{tech}</ul>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProjectDetailsModal;
